import { Select, Trigger } from '@nbit/arco'
import Icon from '@/components/icon'
import LazyImage from '@/components/lazy-image'
import styles from './index.module.css'

function GameFiatTriggerok(props) {
  const { setDefaultPayment, fiatPayInfo, setButtonList, setRoutesData, defaultPayment } = props
  return (
    <div>
      <Trigger
        popupVisible={defaultPayment.popupVisible}
        popup={() => (
          <div className={styles['trigger-layout-menu-wrap']}>
            {fiatPayInfo &&
              fiatPayInfo?.map((res, index) => {
                return (
                  <div
                    key={index}
                    className="trigger-flex"
                    onClick={() => {
                      setButtonList(res?.routes)
                      setRoutesData(res?.routes[0])
                      setDefaultPayment({ ...defaultPayment, payInfo: res, popupVisible: false })
                    }}
                  >
                    <div>
                      <LazyImage src={res?.webLogo || ''} />
                    </div>
                    <div>{res?.channelName || ''}</div>
                  </div>
                )
              })}
          </div>
        )}
        position="bottom"
        trigger={'click'}
      >
        <div
          onClick={() => {
            setDefaultPayment({ ...defaultPayment, popupVisible: !defaultPayment.popupVisible })
          }}
          className="fill-img-box"
        >
          <div>
            <LazyImage src={defaultPayment?.payInfo?.webLogo || ''} />
          </div>
          <div>
            <Icon name={defaultPayment.popupVisible ? 'icon_agent_center_away' : 'icon_agent_center_drop'} />
          </div>
        </div>
      </Trigger>
    </div>
  )
}

export default GameFiatTriggerok
