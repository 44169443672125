/**
 * 法币充值 - 广告模块
 */
import { getFiatDepositTips } from '@/apis/assets/fiat'
import LazyImage, { Type } from '@/components/lazy-image'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { FiatDepositTipsResp } from '@/typings/api/assets/fiat'
import { useState, useEffect } from 'react'
import style from './index.module.css'

interface IFiatAdvertiseProps {
  /** 显示固定图片 */
  onlyImg?: boolean
  imgSrc?: string
}

function FiatAdvertise(props: IFiatAdvertiseProps) {
  const { onlyImg = false, imgSrc } = props
  const [advertiseInfo, setAdvertiseInfo] = useState({} as FiatDepositTipsResp)

  /** 查询底部提示信息 */
  const onLoadAdvertise = async () => {
    const res = await getFiatDepositTips({})
    const { isOk, data } = res || {}
    if (!isOk || !data) return

    setAdvertiseInfo(data)
  }

  useEffect(() => {
    !onlyImg && onLoadAdvertise()
  }, [])

  return (
    <div className={style['fiat-advertise-layout']}>
      {!onlyImg && (
        <div className="fiat-advertise-layout">
          <div dangerouslySetInnerHTML={{ __html: advertiseInfo?.tips || '' }} className="advertise-text" />

          {advertiseInfo?.img && <LazyImage src={advertiseInfo?.img || ''} className="advertise-img" />}
        </div>
      )}

      {onlyImg && (
        <LazyImage
          imageType={Type.png}
          src={imgSrc || `${oss_svg_image_domain_address}bg_fiat_deposit`}
          className="fiat-advertise-img"
        />
      )}
    </div>
  )
}

export { FiatAdvertise }
