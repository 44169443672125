import { Select } from '@nbit/arco'
import Icon from '@/components/icon'
import { useCommonStore } from '@/store/common'
import ListEmpty from '@/components/list-empty'
import { CurrencyModeErationEnum } from '@/constants/common'
import { GameFiatTriggerType } from '@/features/recreation/theme/components/game-fiat-trigger'

const Option = Select.Option

function GameFiatTrigger2z(props: GameFiatTriggerType) {
  const { localeInfo } = useCommonStore()
  const { setDefaultPayment, defaultPayment, fiatPayInfo, setButtonList, setRoutesData, onChangeDiscountList } = props
  return (
    <div>
      <Select
        style={{ width: 536 }}
        className={'brl-select'}
        value={defaultPayment?.currentChannel}
        arrowIcon={<Icon name="icon_agent_center_drop" className="center-drop w-2 h-2" />}
        onChange={value => {
          fiatPayInfo?.map(val => {
            if ((val.channelName || val?.groupCode) === value) {
              setButtonList?.(val?.routes || [])
              setRoutesData?.(val?.routes?.[0] || {})
              localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency &&
                onChangeDiscountList?.(val?.routes?.[0]?.levels || [])
            }
            return val
          })
          setDefaultPayment?.({ ...defaultPayment, currentChannel: value })
        }}
      >
        {fiatPayInfo?.length > 0 ? (
          fiatPayInfo?.map((option, index) => (
            <Option key={index} value={option?.channelName || option?.groupCode || ''}>
              {option?.channelName || option?.groupCode}
            </Option>
          ))
        ) : (
          <ListEmpty />
        )}
      </Select>
    </div>
  )
}

export default GameFiatTrigger2z
