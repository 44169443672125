import GameFiatTrigger2z from '@/features/recreation/theme/2z/game-fiat-trigger'
import GameFiatTriggerok from '@/features/recreation/theme/ok/game-fiat-trigger'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import { AllChargeListResp, AllChargePayInfoResp, ChargeChannels } from '@/typings/api/assets/fiat'

type defaultPaymentType = {
  currentChannel: string
  payInfo: AllChargePayInfoResp
  popupVisible: boolean
}

export type GameFiatTriggerType = {
  /** 当前下拉选中参数 */
  setDefaultPayment?: (e: defaultPaymentType) => void
  defaultPayment: defaultPaymentType
  /** 下拉数据 */
  fiatPayInfo: AllChargePayInfoResp[]
  /** 通道数据 */
  setButtonList?: (e: ChargeChannels[]) => void
  /** 当前选中对象 */
  setRoutesData?: (e: ChargeChannels) => void
  /** 优惠数据 */
  onChangeDiscountList?: (e: AllChargeListResp[]) => void
}

export default function GameFiatTrigger(props: GameFiatTriggerType) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <GameFiatTrigger2z {...props} />
    case InitThemeColor.ok:
      return <GameFiatTriggerok {...props} />
    default:
      return <GameFiatTrigger2z {...props} />
  }
}
