import { useEffect, useState } from 'react'
import CustomModal from '@/features/agent/modal'
import { useCommonStore } from '@/store/common'
import { FiatDepositLayout } from '@/features/assets/main/deposit/fiat-deposit'
import {
  AllChargeListResp,
  AllChargePayInfoResp,
  AllFiatCurrencyResp,
  CodeGetCodeDetailListData,
} from '@/typings/api/assets/fiat'
import { getChargeInfoList, getFiatPayChargeInfoV3 } from '@/apis/assets/fiat'
import { getOssLocaleConfig } from '@/apis/assets/common'
import { WadvertisingConfigResp } from '@/typings/api/assets/assets'
import { CurrencyModeErationEnum } from '@/constants/common'
import style from './index.module.css'

interface FiatDepositLayoutModalProps {
  /** 关闭弹窗回调 */
  setClose: () => void
  /** 法币 */
  fiatCode: string
  /** 打开弹窗 */
  isShowModal: boolean
  paymentDealType: CodeGetCodeDetailListData[]
}

function FiatDepositLayoutModal({ isShowModal, setClose, fiatCode, paymentDealType }: FiatDepositLayoutModalProps) {
  const [fiatPayInfo, setFiatPayInfo] = useState<AllChargePayInfoResp[]>([]) // 支付方式
  const [topUpGradList, setTopUpGradList] = useState<AllChargeListResp[]>([]) // 充值档次
  const [fiatCurrency, setFiatCurrency] = useState<AllFiatCurrencyResp>({} as AllFiatCurrencyResp)
  const [bidList, setBidlist] = useState<WadvertisingConfigResp>({})
  const { localeInfo } = useCommonStore()
  const getChargeListInfo = async fiatCode => {
    const res = await getChargeInfoList({ fiatCode })
    if (res && res.data) {
      const { levels, payInfo, fiatCurrency } = res.data
      addChannelName(payInfo, paymentDealType)
      setTopUpGradList(levels || [])
      setFiatCurrency(fiatCurrency)
    }
  }

  const changeCharge = val => {
    getChargeListInfo(val)
  }
  const queryManyBusiness = async () => {
    const res = await getOssLocaleConfig({})
    if (res?.isOk) {
      setBidlist(res?.data || {})
    }
  }

  /** 获取多币种下法币充值内容 */
  const queryFiatPayChargeInfo = async () => {
    const res = await getFiatPayChargeInfoV3({
      areaCurrency: fiatCode,
    })
    if (res.isOk && res?.data) {
      setFiatPayInfo(res?.data?.payInfo || [])
      setTopUpGradList(res?.data?.payInfo?.[0]?.routes?.[0]?.levels || [])
    }
  }

  useEffect(() => {
    localeInfo?.currencyMode === CurrencyModeErationEnum?.multiCurrency && queryFiatPayChargeInfo()
  }, [])

  useEffect(() => {
    if (isShowModal && localeInfo?.currencyMode !== CurrencyModeErationEnum?.multiCurrency) {
      queryManyBusiness()
      getChargeListInfo(fiatCode)
    }
  }, [isShowModal])

  const addChannelName = (payInfo, channelInfo) => {
    for (const info of payInfo) {
      const matchingChannel = channelInfo.find(item => info.groupCode === item.codeVal)
      if (matchingChannel) {
        info.channelName = matchingChannel.codeKey
      } else {
        info.channelName = info?.groupCode
      }
    }
    setFiatPayInfo(payInfo)
  }

  return (
    <div>
      <CustomModal style={{ width: 600 }} className={style['scoped-fill']} visible={isShowModal}>
        <FiatDepositLayout
          fastManyBusiness={bidList}
          fiatPayInfo={fiatPayInfo}
          topUpGradList={topUpGradList}
          fiatCurrency={fiatCurrency}
          firstFiatCode={fiatCode}
          onclick={setClose}
          changeCharge={changeCharge}
        />
      </CustomModal>
    </div>
  )
}

export { FiatDepositLayoutModal }
